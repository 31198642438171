import React from 'react';

import './sitemap.css';

export default function Home() {
    {document.title = 'Phil Daniel | Sitemap';}
    return (<>
        <div className="sitemap-content">
            <h1>
                Sitemap
            </h1>
            <p>Work in Progress!</p>
        </div>
    </>);
}